import React from 'react'
import { useAppContext } from '../contexts/AppContext'

const Title = ({ children}) => <h2 className='mt-8 font-bold'>{children}</h2>

const Paragraph = ({ children }) => <p className='mt-4'>{children}</p>

const FrenchTermsAndConditions = () => (
  <div>
    <Paragraph>
      Je suis créatrice de poupées reborns depuis mars 2021.
      Les poupées sont peintes à la main avec de la peinture à cuire Génésis, elles sont vernies pour protéger la peinture.<br />
      Elles sont également lestées aux microbilles de verre et à la ouate, et rootées avec un mohair de qualité (Heike Politz, Susan Nagel ou Slumberland)
    </Paragraph>

    <Paragraph>
      Chaque poupée nécessite un grand nombre d'heures de travail.
      Je ne travaille que sur des kits authentiques avec certificat d'authenticité et gravure (facture à l'appui).
    </Paragraph>

    <Paragraph>
      Les poupées reborns sont avant tout des poupées d'art et de collection, elles ne sont donc pas destinées à de jeunes enfants.
    </Paragraph>

    <Title>Commandes personnalisées :</Title>
    <Paragraph>
      Je peux réaliser des poupées sur commande, sur mes kits en stock ou ceux que j'ai précommandés, listés sur <a href='/custom_reborn'>cette page</a>.<br />
      Je peux également réaliser le kit de votre choix s'il est disponible chez un revendeur officiel (MacPherson, Bebaby, rebornshopbaby)
    </Paragraph>

    <Paragraph>
      La commande ne sera validée qu'après versement du 1er paiement.<br />
      Je demande un acompte de 180€ qui couvre l'achat du kit, du matériel (mohair, peinture, ...) et des accessoires. Cet acompte est non remboursable car il engage des dépenses de ma part.<br />
      Le processsus de création commence alors. Il faudra compter 8 à 10 semaines de délai pour la réalisation.
    </Paragraph>

    <Paragraph>
      Vous pouvez choisir le sexe (ou me laisser choisir), l'ethnicité et les détails qui donneront du réalisme à la poupée (milia, pelures aux chevilles, rougeurs, griffures)<br />
      Mes reborns sont rootés avec la technique du mono-rooting directionnel. Pour l'instant, je ne propose pas de hair painting.
    </Paragraph>

    <Paragraph>
      Tous mes reborns endormis (entre 43cm et 51cm) sont proposés au prix de 400€+envoi.
    </Paragraph>

    <Paragraph>
      Les toddlers (taille supérieure à 51cm) sont proposés au prix de 450€+envoi.
    </Paragraph>

    <Paragraph>Pour les reborns aux yeux ouverts il faudra rajouter 25€, quelle que soit la taille du kit.</Paragraph>

    <Paragraph>
      Si le kit n'est pas fourni avec une plaque ventrale, il est possible d'en ajouter une pour 50€ supplémentaires.<br />
      Il est également possible de rajouter dans le body un petit cœur qui bat quand on appuie dessus pour 20€ supplémentaires.
    </Paragraph>

    <div className='mt-4'>
      Tous mes bébés personnalisés repartent avec:<br />
      <ul className='ml-4 list-disc'>
        <li>une tenue de nuit</li>
        <li>une tenue de jour</li>
        <li>leur certificat d'authenticité (COA)</li>
        <li>leur certificat de nurserie</li>
        <li>une tétine aimantée et son attache tétine</li>
        <li>un doudou</li>
        <li>un lange</li>
        <li>un bandeau et/ou un bonnet</li>
        <li>un bracelet de naissance</li>
        <li>un kit de soin capillaire</li>
      </ul>
    </div>

    <Title>Paiement :</Title>
    <Paragraph>
      Le règlement s'effectue par virement bancaire ou via Paypal.<br />
      J'accepte les paiements en 3 fois, l'acheteur doit respecter son échéancier.<br />
      Le solde doit être payé en totalité avant l'expédition.
    </Paragraph>

    <Title>Livraison :</Title>
    <Paragraph>
      La commande est expédiée via Colissimo avec signature et assurance (au prix de la poupée).<br />
      Pour la France, le coût d'expédition est d'environ 22€, en Europe 35€ et à l'international 72€ (prix à titre indicatif dépendant du poids du colis)
    </Paragraph>

    <Title>Retour et remboursement :</Title>
    <Paragraph>
      Aucun retour ou remboursement ne sera effectué sur les commandes sur mesure ou personnalisées, conformément à l'article L 121-20-2 du code de la consommation, le client ne peut exercer son droit de rétractation.
    </Paragraph>
  </div>
)

const EnglishTermsAndConditions = () => (
  <div>
    <Paragraph>
      I am a reborn doll maker since March 2021.
      The dolls are hand-painted with Génésis baking paint and varnished to protect the paint.<br />
      Elles sont également lestées aux microbilles de verre et à la ouate, et rootées avec un mohair de qualité (Heike Politz, Susan Nagel ou Slumberland)
    </Paragraph>

    <Paragraph>
      Each doll requires a large number of hours of work.<br />
      I only work on authentic kits with certificate of authenticity and engraving (with invoice).
    </Paragraph>


    <Title>Customized orders:</Title>
    <Paragraph>
      I can make dolls to order, from my in-stock kits or those I have pre-ordered, listed on <a href='/custom_reborn'>this page</a>.<br />
      I can also make the kit of your choice if it is available from an official dealer (MacPherson, Bebaby, rebornshopbaby)
    </Paragraph>

   <Paragraph>
      The order will be validated only after payment of the 1st payment.
      I ask for a deposit of 180€ which covers the purchase of the kit, the material (mohair, paint, ...) and the accessories. This deposit is not refundable because it involves expenses on my part.
      The creation process then begins. It will take 8 to 10 weeks to complete.
    </Paragraph>

    <Paragraph>
      You can choose the gender (or let me choose), the ethnicity and the details that will give realism to the doll (milia, peeling ankles, redness, scratches)
      My reborns are rooted with the directional mono-rooting technique. I don't realize any hair painting for the moment.
    </Paragraph>

    <Paragraph>
      All my sleeping reborns (between 43cm and 51cm) are sold at 400€ + shipping.
    </Paragraph>

    <Paragraph>
      Toddlers (over 51cm) are sold at 450€ + shipping.
    </Paragraph>

    <Paragraph>
      For open-eyed reborns, you will have to add 25€, whatever the kit size.
    </Paragraph>

    <Paragraph>
      If the kit does not come with a belly plate, it is possible to add one for 50€ extra.
      It is also possible to add a little heart in the body that beats when you press on it for an extra 20€.
    </Paragraph>

    <div className='mt-4'>
      All my personalized babies come with:<br />
      <ul className='ml-4 list-disc'>
        <li>a night outfit</li>
        <li>a day outfit</li>
        <li>their certificate of authenticity (COA)</li>
        <li>their nursery certificate</li>
        <li>a magnetic pacifier and its pacifier clip</li>
        <li>a comforter</li>
        <li>a diaper</li>
        <li>a headband and/or beanie</li>
        <li>a birth bracelet</li>
        <li>a hair care kit</li>
      </ul>
    </div>

    <Title>Payment:</Title>
    <Paragraph>
      Payment is made by bank transfer or via Paypal.
      I accept payments in 3 instalments, buyers must respect their schedule.
      The balance must be paid in full before shipping.
    </Paragraph>

    <Title>Delivery:</Title>
    <Paragraph>
      The order is shipped via Colissimo with signature and insurance (at the price of the doll).
      For France, the shipping cost is about 22€, in Europe 35€ and internationally 72€ (indicative price depending on the weight of the package)
    </Paragraph>

    <Title>Return and refund:</Title>
    <Paragraph>
      No return or refund will be made on custom or personalized orders, in accordance with Article L 121-20-2 of the Consumer Code, customers can not exercise theirs right of withdrawal.
    </Paragraph>
  </div>
)

const TermsAndConditions = () => {
  const { currentLocale } = useAppContext()

  if (currentLocale === 'en') return <EnglishTermsAndConditions />

  return <FrenchTermsAndConditions />
}

export default TermsAndConditions