import React from 'react'
import { Link, BrowserRouter as Router, Switch, Route, useLocation, useParams} from 'react-router-dom'
import classNames from 'classnames'
import I18n from 'i18n-js'
import { useAppContext, AppContextProvider } from '../contexts/AppContext'
import { CartContextProvider, useCartContext } from '../contexts/CartContext'
import Dropdown from './Dropdown'
import SeoMetaTags from './SeoMetaTags'
import Cart from './Cart'
import RebornsList from './RebornsList'
import RebornPage from './RebornPage'
import TermsAndConditions from './TermsAndConditions'
import Logo from '../images/logo_nomargin.jpeg'
import CartIcon from '../images/shopping-cart.png'
import FacebookIcon from '../images/facebook.svg'
import TiktokIcon from '../images/tiktok.svg'
import InstagramIcon from '../images/instagram.svg'
import BannerAvailable from '../images/banner_available.png'

const FACEBOOK_URL = 'https://www.facebook.com/LovelyMelodyReborn/'
const TIKTOK_URL = 'https://www.tiktok.com/@lovely_melody_reborn'
const INSTAGRAM_URL = 'https://www.instagram.com/lovely_melody_reborn/'

const Flag = ({ className, locale, onClick, selected }) => (
  <span className={classNames('cursor-pointer', className, { 'font-bold': selected })} onClick={() => onClick(locale)}>{locale.toUpperCase()}</span>
)

const Header = ({ cartSize, currentLocale, onLocaleChange }) => {
  const location = useLocation()

  const menuEntryClassName = (linkTo) => {
    const selected = location.pathname === linkTo
    return { 'font-normal': selected, 'font-extralight': !selected }
  }

  const getTitleProps = () => {
    if (location.pathname === '/') {
      return { name: I18n.t('headers.available'), subtitle: I18n.t('headers.ready_to_ship') }
    } else if (location.pathname === '/custom_reborn') {
      return { name: I18n.t('headers.custom'), subtitle: I18n.t('headers.personalized_reborn') }
    } else if (location.pathname === '/portfolio') {
      return { name: I18n.t('headers.portfolio') }
    }
    return {}
  }

  const titleProps = getTitleProps()

  return(
    <>
      <div id='header' className='flex flex-row p-4 border-b-2 justify-between items-center'>
        <div className='hidden lg:flex flex-row items-center'>
          <Link to={'/'}><img src={Logo} title='Lovely Melody Reborn' alt='Lovely Melody Reborn logo' width='120' height='120' style={{ minWidth: '120px', height: '120px' }} /></Link>
          <Link to={'/'} className={classNames('ml-8', menuEntryClassName('/'))}>{I18n.t('available_reborns')}</Link>
          <Link to={'/custom_reborn'} className={classNames('ml-8', menuEntryClassName('/custom_reborn'))}>{I18n.t('custom_reborn')}</Link>
          <Link to={'/portfolio'} className={classNames('ml-8', menuEntryClassName('/portfolio'))}>Portfolio</Link>
          <Link to={'/t_and_c'} className={classNames('ml-8', menuEntryClassName('/t_and_c'))}>{I18n.t('t_and_c')}</Link>
        </div>
        <div className='hidden lg:flex'>
          <Link to={'/cart'} className='mr-4'>
            <img id="cart-icon" title={I18n.t('cart')} alt='Shopping cart icon' src={CartIcon} width='20' height='20' />
            {cartSize > 0 && ` (${cartSize})`}
          </Link>
          <Flag locale='en' selected={currentLocale === 'en'} onClick={onLocaleChange} />
          <Flag locale='fr' selected={currentLocale === 'fr'} onClick={onLocaleChange} className='ml-2' />
        </div>
        <div className='flex lg:hidden flex-row items-center'>
          <Link to={'/'}><img src={Logo} title='Lovely Melody Reborn' alt='Lovely Melody Reborn logo' width='120' height='120' style={{ minWidth: '120px', height: '120px' }} /></Link>
        </div>
        <div className='flex lg:hidden flex-row items-center'>
          <Dropdown className='ml-8' top={90} right={16}>
            <Link to={'/'} className={classNames(menuEntryClassName('/'))}>{I18n.t('available_reborns')}</Link>
            <Link to={'/custom_reborn'} className={classNames(menuEntryClassName('/custom_reborn'))}>{I18n.t('custom_reborn')}</Link>
            <Link to={'/portfolio'} className={classNames(menuEntryClassName('/portfolio'))}>Portfolio</Link>
            <Link to={'/t_and_c'} className={classNames(menuEntryClassName('/t_and_c'))}>{I18n.t('t_and_c')}</Link>
            <Link to={'/cart'} className={classNames(menuEntryClassName('/cart'))}>
              {I18n.t('cart')}
              {cartSize > 0 && ` (${cartSize})`}
            </Link>
            <div className='mt-8 flex justify-between'>
              <Flag locale='en' selected={currentLocale === 'en'} onClick={onLocaleChange} />
              <Flag locale='fr' selected={currentLocale === 'fr'} onClick={onLocaleChange} />
            </div>
          </Dropdown>
        </div>
      </div>
      {titleProps.name && (
        <div className='relative'>
          <div className='w-full' style={{ height: '300px', backgroundImage: `url(${BannerAvailable})`, backgroundSize: 'cover', backgroundPositionX: 'center' }} />
          <div className='w-full absolute flex justify-center items-end' style={{ height: '300px', bottom: '30px' }}>
            <h1 className='text-center' style={{ color: 'black' }}>
              <span className='font-bold text-3xl'>{titleProps.name}</span>
              {titleProps.subtitle && (
                <> - <span className='font-semibold text-base'>{titleProps.subtitle}</span></>
              )}
            </h1>
          </div>
        </div>
      )}
    </>
  )
}

const Footer = () => {
  const openExternalLink = (url) => window.open(url, '_blank')

  return (
    <div id='footer'>
      <div id='social-network'>
        <img onClick={() => openExternalLink(FACEBOOK_URL)} src={FacebookIcon} alt='Facebook icon' width='20' height='20' className="inline-block cursor-pointer" />
        <img onClick={() => openExternalLink(TIKTOK_URL)} src={TiktokIcon} alt='Tiktok icon' width='20' height='20' className="inline-block cursor-pointer" />
        <img onClick={() => openExternalLink(INSTAGRAM_URL)} src={InstagramIcon} alt='Instagram icon' width='20' height='20' className="inline-block cursor-pointer" />
      </div>
      © {new Date().getFullYear()}, Lovely Melody Reborn
    </div>
  )
}

const App = (props) => {
  const { currentLocale, setCurrentLocale } = useAppContext()
  const { content } = useCartContext()

  return (
    <Router>
      <SeoMetaTags reborns={props.reborns} reborn={props.reborn} />
      <Header currentLocale={currentLocale} onLocaleChange={setCurrentLocale} cartSize={content.reborns.length} />
      <div className='px-2 sm:px-8 my-8 flex-1'>
        <Switch>
          <Route path="/cart" component={Cart} />
          <Route path="/custom_reborn" render={() => <RebornsList status={'received'} reborns={props.reborns.filter(({ status }) => status === 'received')} />} />
          <Route path="/portfolio" render={() => <RebornsList status={'sold'} reborns={props.reborns.filter(({ status }) => status === 'sold')} />} />
          <Route path="/t_and_c" render={() => <TermsAndConditions />} />
          <Route path="/reborns/:slug" component={() => {
            const { slug } = useParams()
            const reborn = props.reborns.find((reborn) => reborn.slug === slug)
            return <RebornPage reborn={reborn} showPrice={reborn.status !== 'sold' } />
          }}>
          </Route>
          <Route path="/">
            <RebornsList status={'for_sale'} reborns={props.reborns.filter(({ status }) => status === 'for_sale')} />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  )
}

const AppWithContexts = (props) => {
  return (
    <AppContextProvider initialLocale={I18n.locale}>
      <CartContextProvider>
        <div className='flex flex-col h-screen'><App {...props} /></div>
      </CartContextProvider>
    </AppContextProvider>
  )
}

export default AppWithContexts
