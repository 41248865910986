import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import I18n from 'i18n-js'
import Magnifier from 'react-magnifier'
import { useCartContext } from '../contexts/CartContext'
import Breadcrumb from './Breadcrumb'
import LazyLoadedImage from './LazyLoadedImage'
import Spinner from '../images/spinner.gif'

const CustomPriceInformation = ({ kitPrice }) => {
  return (<div className='mt-8'>
    <span className='font-bold'>{I18n.t('custom_pricing.base_price')}</span>: {kitPrice + 230}€<br />
    <p className='mt-4'>
      <span className='font-bold'>{I18n.t('custom_pricing.options')}</span><br />
      <span>{I18n.t('custom_pricing.rooting')}</span>: +100€<br />
      <span>{I18n.t('custom_pricing.awake')}</span>: +30€<br />
      <span>{I18n.t('custom_pricing.plates.front')}</span>: +30€<br />
      <span>{I18n.t('custom_pricing.plates.back')}</span>: +30€<br />
    </p>

    <p className='mt-4'>
      <span className='font-bold'>{I18n.t('custom_pricing.shipping')}</span><br />
      <span>{I18n.t('custom_pricing.sending_locations.france')}</span>: 20€<br />
      <span>{I18n.t('custom_pricing.sending_locations.europe')}</span>: 30€<br />
      <span>{I18n.t('custom_pricing.sending_locations.other')}</span>: 70€<br />
    </p>

    <p className='mt-4'>
      <span>{I18n.t('custom_pricing.taxes_disclaimer')}</span>
    </p>
  </div>)
}

const RebornPage = ({ reborn, showPrice = true }) => {
  const { addToCart, cartContains, removeFromCart } = useCartContext()
  const [imageZoomed, setImageZoomed] = useState()
  const [objectFit, setObjectFit] = useState(null)
  const [pictures, setPictures] = useState([])

  const rebornInCart = cartContains(reborn)

  const handleAddToCardClicked = () => {
    if (rebornInCart) {
      removeFromCart(reborn)
    } else {
      addToCart(reborn)
    }
  }

  useEffect(() => {
    fetch(`/api/reborns/${reborn.id}`)
      .then((response) => response.json())
      .then((response) => {
        setPictures(response.pictures)
      })
  }, [])

  const handleAskForInformationClicked = (slug) => {
    window.open(`https://m.me/LovelyMelodyReborn?ref=${slug}`, '_blank')
  }

  const handleCloseModal = () => {
    setImageZoomed(null)
    setObjectFit(null)
  }

  useEffect(() => {
    if (imageZoomed) {
      let dummyImage = new Image()
      dummyImage.onload = () => {
        const width = dummyImage.naturalWidth * 1.0
        const height = dummyImage.naturalHeight * 1.0
        const imageRatio = width / height

        const containerWidth = document.querySelector('#magnifier-container').clientWidth
        const containerHeight = document.querySelector('#magnifier-container').clientHeight
        const containerRatio = containerWidth / containerHeight

        if (imageRatio > containerRatio) {
          setObjectFit('landscape')
        } else {
          setObjectFit('portrait')
        }
      }
      dummyImage.src = imageZoomed
    }
  }, [imageZoomed])

  return (
    <>
      <Breadcrumb kitName={reborn.kitName} status={reborn.status} />
      <div className='flex flex-row'>
        <div className='w-1/2'>
          <img className='ml-1' style={{ maxHeight: '300px' }} src={reborn.coverPicture.normal} alt={`${reborn.kitName} by ${reborn.sculptedBy}`} onClick={() => setImageZoomed(reborn.coverPicture.normal) } />
          <div className='mt-4 flex flex-row' style={{ flexWrap: 'wrap' }}>
            {pictures.map((picture, index) => (
              <LazyLoadedImage
                withWrapper
                effect='blur'
                threshold='100'
                key={index}
                alt={`${reborn.kitName} by ${reborn.sculptedBy}, ${index}/${pictures.length}`}
                src={picture.normal}
                placeholder={<span width='150' height={`${picture.height / (picture.width / 150.0)}px`}><img src={Spinner} width='30' height='30' alt='loading spinner' /></span>}
                width='150px'
                visibleByDefault={false}
                height={`${picture.height / (picture.width / 150.0)}px`}
                onClick={() => setImageZoomed(picture.normal) } />
            ))}
          </div>
        </div>
        <div className='w-1/2 ml-8'>
          <h1 className='font-semibold'><span>{reborn.kitName}</span> by <span>{reborn.sculptedBy}</span></h1>
          {showPrice && reborn.normalPrice && (<div>
            <span className={classNames('normal-price', { 'line-through': !!reborn.salePrice })}>{reborn.normalPrice}€</span>
            &nbsp;
            {!!reborn.salePrice && (
            <>
              <span className='sale-price'>{reborn.salePrice}€</span>
              &nbsp;
            </>)}
            <span>{I18n.t('including_tax')}</span>
          </div>)}
          {reborn.bornOn && <div>{I18n.t('born_on')} {I18n.l("date.formats.custom_short", reborn.bornOn)}</div>}

          {reborn.status === 'for_sale' && (
            <div className='w-100 px-2 py-4 my-4 cursor-pointer uppercase text-center text-white bg-blue-400 hover:bg-blue-500' onClick={handleAddToCardClicked}>
              {rebornInCart ? I18n.t('remove_from_cart') : I18n.t('add_to_cart')}
            </div>
          )}
          {(reborn.status === 'for_sale' || reborn.status === 'received') && (
            <div className='w-100 px-2 py-4 my-4 cursor-pointer uppercase text-center text-white bg-blue-400 hover:bg-blue-500' onClick={() => handleAskForInformationClicked(reborn.slug) }>
              {I18n.t('ask_for_informations')}
            </div>
          )}
          <div dangerouslySetInnerHTML={{ __html: reborn.description?.body }} />

          {reborn.status === 'received' && reborn.kitPrice && <CustomPriceInformation kitPrice={reborn.kitPrice} />}
        </div>
        {imageZoomed && (
          <div className='w-full' onClick={handleCloseModal} style={{
            left: 0,
            top: 0,
            bottom: 0,
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }}>
            <div id='magnifier-container' onClick={(event) => event.stopPropagation()} className='bg-white my-8 mx-auto border px-4 py-8 rounded-lg relative flex justify-center' style={{ width: '80%', height: 'calc(100% - 4rem)' }}>
              {objectFit && <Magnifier src={imageZoomed} className={classNames({ [objectFit]: true })} width='auto' height='auto' />}
              <div className='absolute top-2 right-3 cursor-pointer' onClick={handleCloseModal}>X</div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default RebornPage
