import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const BreadcrumbItem = ({ path, label }) => {
  const location = useLocation()

  if (path && path !== location.pathname) {
    return <span>&nbsp;&gt;&nbsp;<Link to={path}>{label}</Link></span>
  }
  return <span>&nbsp;&gt;&nbsp;{label}</span>
}

const Breadcrumb = ({ kitName, status }) => {
  const location = useLocation()
  if (!kitName) return null

  return (
    <div className='mb-8'>
      {status === 'for_sale' && <Link to={'/'}>{I18n.t('available_reborns')}</Link>}
      {status === 'received' && <Link to={'/custom_reborn'}>{I18n.t('headers.personalized_reborn')}</Link>}
      {status === 'sold' && <Link to={'/portfolio'}>{I18n.t('headers.portfolio')}</Link>}
      {kitName && <BreadcrumbItem label={kitName} />}
    </div>
  )
}

export default Breadcrumb
