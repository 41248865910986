const en_root = {
  add_to_cart: 'Add to cart',
  ask_for_informations: 'Ask for information',
  available_reborns: 'Available reborns',
  born_on: 'Born on',
  cart: 'Cart',
  common: {
    labels: {
      kit: 'Kit',
      price: 'Price'
    }
  },
  contact_seller: 'Contact our nursery',
  contact_email: 'Contact email address',
  custom_reborn: 'Custom reborn',
  custom_pricing: {
    awake: 'Awake (glass eyes)',
    base_price: 'Base price',
    paypal_fees: 'Paypal fees',
    plates: {
      back: 'Dorsal plate',
      front: 'Belly plate (gendered or not)'
    },
    rooting: 'Rooting',
    shipping: 'Shipping fees',
    sending_locations: {
      europe: 'Europe — Colissimo with signature and insurance',
      france: 'France — Colissimo with signature and insurance',
      other: 'Other — Signature and insurance'
    },
    taxes_disclaimer: 'All prices include taxes',
    options: 'Options'
  },
  date: {
    formats: {
      custom_short: '%m/%d/%y'
    }
  },
  discount: 'Sale',
  headers: {
    available: 'Available',
    custom: 'Customs',
    portfolio: 'Portfolio',
    personalized_reborn: 'Personalized reborns',
    ready_to_ship: 'Ready to ship'
  },
  including_tax: 'VAT incl.',
  items: 'item(s)',
  navigate: 'Navigate',
  online_payment_disclaimer: "The nursery does not accept payments without prior contact yet. We will be notified of your request by email and will get back to you as soon as possible.",
  order_confirmed: 'Your demand was succesfully taken into account. We will get back to you as soon as possible.',
  order_error: 'An error occurred',
  remove_from_cart: 'Remove from cart',
  t_and_c: 'Terms and Conditions',
  total_price: 'Total price',
  your_cart_is_empty: 'Your cart is empty'
}

export default en_root
