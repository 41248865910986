import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const LazyLoadedImageWrapper = ({ children, height }) => <div style={{ margin: '0.25rem', height }}>{children}</div>

const UnwrappedLazyLoadedImage = ({ children }) => <>{children}</>

const LazyLoadedImage = ({ withWrapper = false, ...props }) => {
  const Component = withWrapper ? LazyLoadedImageWrapper : UnwrappedLazyLoadedImage
  return (
    <Component height={props.height}>
      <LazyLoadImage {...props} />
    </Component>
  )
}

export default LazyLoadedImage
