import React, { useState } from 'react'
import I18n from 'i18n-js'
import { useCartContext } from '../contexts/CartContext'
import ShoppingCartRebornCard from './ShoppingCartRebornCard'
import classNames from 'classnames'

const EmptyCart = () => (
  <div className='flex flex-row'>
    {I18n.t('your_cart_is_empty')}
  </div>
)

const SuccessPanel = () => (
  <div className='disclaimer-color mb-8'>
    {I18n.t('order_confirmed')}
  </div>
)

const OrderPanel = ({ onSuccess }) => {
  const { content } = useCartContext()

  const [email, setEmail] = useState('')
  const [sendingOrder, setSendingOrder] = useState(false)

  const handleContactSeller = () => {
    setSendingOrder(true)
    const form = new FormData()
    form.append('email', email)
    form.append('reborn_ids', content.reborns.map((reborn) => reborn.id))
    fetch('/api/orders', { method: 'POST', headers: {}, body: form })
      .then(
        onSuccess,
        () => {
          setSendingOrder(false)
          alert(I18n('order_error'))
        }
      )
  }

  const handleEmailChanged = (event) => setEmail(event.target.value)

  const validEmail = email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/)

  return (
    <div>
      <div className='disclaimer-color mb-8'>
        {I18n.t('online_payment_disclaimer')}
      </div>
      <form>
        {I18n.t('contact_email')}
        <input className='border w-full h-8 px-2' value={email} onChange={handleEmailChanged} />
        <div
          className={classNames('w-100 px-2 py-4 mt-4 uppercase text-center text-white', { 'bg-blue-400 hover:bg-blue-500 cursor-pointer': validEmail && !sendingOrder, 'bg-blue-200': !validEmail || sendingOrder })}
          disabled={!validEmail || sendingOrder}
          onClick={handleContactSeller}>
          {I18n.t('contact_seller')}
        </div>
      </form>
    </div>
  )
}

const Summary = () => {
  const { content } = useCartContext()

  const [success, setSuccess] = useState(false)

  const originalPrice = content.reborns.reduce((memo, reborn) => memo + reborn.normalPrice, 0)
  const discount = originalPrice - content.reborns.reduce((memo, reborn) => memo + (reborn.salePrice || reborn.normalPrice) , 0)
  const totalPrice = originalPrice - discount

  return (
    <div className='flex flex-col flex-none w-full mt-8 sm:mt-0 sm:w-1/3 sm:h-full px-4 justify-between'>
      <div className='flex flex-col mb-8 sm:mb-0'>
        <div className='flex flex-row justify-between'>
          <span>{content.reborns.length} {I18n.t('items')}</span>
          <span className='font-bold'>{originalPrice}€ {I18n.t('including_tax')}</span>
        </div>
        {discount > 0 && (
          <>
            <div className='flex flex-row justify-between'>
              <span>{I18n.t('discount')}</span>
              <span className='font-bold'>{discount}€ {I18n.t('including_tax')}</span>
            </div>
            <div className='flex flex-row justify-between'>
              <span>{I18n.t('total_price')}</span>
              <span className='font-bold'>{totalPrice}€ {I18n.t('including_tax')}</span>
            </div>
          </>)}
      </div>
      {!success && <OrderPanel onSuccess={() => setSuccess(true) } />}
      {success && <SuccessPanel />}
    </div>
  )
}

const Cart = () => {
  const { content } = useCartContext()

  if (content.reborns.length === 0) return <EmptyCart />

  return (
    <div className='flex flex-col sm:flex-row sm:h-full sm:divide-x'>
      <div className='flex flex-col flex-1'>
        {content.reborns.map((reborn) => {
          return <ShoppingCartRebornCard key={reborn.slug} reborn={reborn} className='mx-2' />
        })}
      </div>
      <Summary />
    </div>
  )
}

export default Cart
