import React from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import LazyLoadedImage from './LazyLoadedImage'

const RebornCard = ({ className, reborn }) => {
  const history = useHistory()

  return (
    <div className={classNames('reborn-card small cursor-pointer p-2 hover:bg-gray-100', className)} onClick={() => history.push(`/reborns/${reborn.slug}`)}>
      <LazyLoadedImage className='reborn-cover-thumbnail' src={reborn.coverPicture.normal} alt={`${reborn.kitName} by ${reborn.sculptedBy}`} width='220px' height='220px' />
      <div className='title font-semibold'><span>{reborn.kitName}</span> by <span>{reborn.sculptedBy}</span></div>
      {reborn.status !== 'sold' && reborn.normalPrice && (<div>
        <span className={classNames('normal-price', { 'line-through': !!reborn.salePrice })}>{reborn.normalPrice}€</span>
        &nbsp;
        {!!reborn.salePrice && <span className='sale-price'>{reborn.salePrice}€</span>}
      </div>)}
      {reborn.bornOn && <div>{I18n.t('born_on')} {I18n.l("date.formats.custom_short", reborn.bornOn)}</div>}
    </div>
  )
}

export default RebornCard
