const fr_root = {
  add_to_cart: 'Ajouter au panier',
  ask_for_informations: 'Demander des informations',
  available_reborns: 'Reborn disponibles',
  born_on: 'Né(e) le',
  cart: 'Panier',
  common: {
    labels: {
      kit: 'Kit',
      price: 'Prix'
    }
  },
  contact_seller: 'Contacter la nurserie',
  contact_email: 'Adresse email de contact',
  custom_reborn: 'Reborn personnalisé',
  custom_pricing: {
    awake: 'Éveillé (yeux en verre)',
    base_price: 'Prix de base',
    paypal_fees: 'Frais Paypal',
    plates: {
      back: 'Plaque dorsale',
      front: 'Plaque ventrale (sexuée ou non)'
    },
    rooting: 'Rooting',
    shipping: 'Frais de port',
    sending_locations: {
      europe: 'Europe — Colissimo avec signature et assurance',
      france: 'France — Colissimo avec signature et assurance',
      other: 'Autre — Signature et assurance'
    },
    taxes_disclaimer: 'Tous les prix sont exprimés toutes taxes comprises (TTC)',
    options: 'Options'
  },
  date: {
    formats: {
      custom_short: '%d/%m/%y'
    }
  },
  discount: 'Remise',
  headers: {
    available: 'Disponibles',
    custom: 'Customs',
    portfolio: 'Portfolio',
    personalized_reborn: 'Reborns personnalisés',
    ready_to_ship: 'Prêts à partir'
  },
  including_tax: 'TTC',
  items: 'article(s)',
  navigate: 'Navigation',
  online_payment_disclaimer: "La nurserie n'accepte pas encore les paiements sans un contact préalable. Nous serons avertis par email de votre demande et reviendrons vers vous au plus vite.",
  order_confirmed: 'Votre demande a bien été prise en compte. Nous vous recontacterons dès que possible.',
  order_error: 'Une erreur est survenue',
  remove_from_cart: 'Retirer du panier',
  t_and_c: 'Conditions Générales de Vente',
  total_price: 'Prix total',
  your_cart_is_empty: 'Votre panier est vide'
}

export default fr_root
