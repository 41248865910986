import React from 'react'
import classNames from 'classnames'
import { useCartContext } from '../contexts/CartContext'
import I18n from 'i18n-js'

const ShoppingCartRebornCard = ({ className, reborn }) => {
  const { removeFromCart } = useCartContext()

  return (
    <div className={classNames('reborn-card p-2 hover:bg-gray-100 flex flex-row justify-between', className)}>
      <div className='flex flex-row'>
        <img className='reborn-cover-thumbnail small' src={reborn.coverPicture.normal} />
        <div className='flex flex-col ml-4'>
          <div className='title font-semibold'>Kit</div>
          <div><span>{reborn.kitName}</span> by <span>{reborn.sculptedBy}</span></div>
          <br />
          {reborn.bornOn && <div>{I18n.t('born_on')} {I18n.l("date.formats.custom_short", reborn.bornOn)}</div>}
        </div>
      </div>
      <div className='flex flex-row items-center'>
        {reborn.status !== 'sold' && reborn.normalPrice && (<div>
          <span className={classNames('normal-price', { 'line-through': !!reborn.salePrice })}>{reborn.normalPrice}€</span>
          &nbsp;
          {!!reborn.salePrice && <span className='sale-price'>{reborn.salePrice}€</span>}
        </div>)}
        <div className='cursor-pointer ml-4' title={I18n.t('remove_from_cart')} onClick={() => removeFromCart(reborn)}>X</div>
      </div>
    </div>
  )
}

export default ShoppingCartRebornCard
