import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import BurgerMenuIcon from '../images/icon_burger_menu.svg'
import I18n from 'i18n-js'

const Dropdown = ({ className, children, top, right}) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)

  const handleDropdownToggled = () => {
    setOpen(!open)
  }

  useEffect(() => {
    setOpen(false)
  }, [location.pathname])

  return (
    <>
      <div className={classNames('flex flex-row items-center', className)} onClick={handleDropdownToggled}><img title={I18n.t('navigate')} alt='Menu icon' src={BurgerMenuIcon} width='32' height='32' /></div>
      {open && (
        <div className='absolute bg-white z-10 rounded-lg p-4 border border-slate-300 flex flex-col' style={{ top: `${top}px`, right: `${right}px` }}>
          {children}
        </div>
      )}
    </>
  )
}

export default Dropdown
