import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useAppContext } from '../contexts/AppContext'

const SeoMetaTags = (props) => {
  const WEBSITE = 'Lovely Melody Reborn'
  const DEFAULT_DESCRIPTION = {
    fr: 'Lovely Melody Reborn, poupées reborn par Audrey',
    en: 'Lovely Melody Reborn, art dolls by Audrey'
  }
  const HOSTNAME = `${window.location.protocol}//${window.location.hostname}`
  const TITLES = {
    fr: {
      home: 'Reborn disponibles',
      cart: 'Panier',
      portfolio: 'Portfolio',
      custom_reborn: 'Reborns personnalisés',
      t_and_c: 'Conditions générales de vente'
    },
    en: {
      home: 'Available reborns',
      cart: 'Cart',
      portfolio: 'Portfolio',
      custom_reborn: 'Custom reborns',
      t_and_c: 'Terms and conditions'
    }
  }

  const { currentLocale } = useAppContext()
  let description = DEFAULT_DESCRIPTION[currentLocale]

  const location = useLocation()
  const pathname = location.pathname

  let reborn = null
  if (pathname.match(/\/reborns\//)) {
    const slug = pathname.split('/').slice(-1)
    if (slug.length > 0) {
      reborn = props.reborns.find((reborn) => reborn.slug === slug[0])
    }
  }

  const getPageTitle = (locale, path, reborn) => {
    if (reborn) {
      return `${WEBSITE} — ${reborn.kitName} by ${reborn.sculptedBy}`
    }

    switch(path) {
      case '/':
        return `${WEBSITE} — ${TITLES[locale].home}`
      case '/cart':
        return `${WEBSITE} — ${TITLES[locale].cart}`
      case '/portfolio':
        return `${WEBSITE} — ${TITLES[locale].portfolio}`
      case '/custom_reborn':
        return `${WEBSITE} — ${TITLES[locale].custom_reborn}`
      case '/t_and_c':
        return `${WEBSITE} — ${TITLES[locale].t_and_c}`
      default:
        return WEBSITE
    }
  }
  const title = getPageTitle(currentLocale, pathname, reborn) || WEBSITE

  return (
    <Helmet>
      <title>{title}</title>
      {description && (
        <meta
          name="description"
          content={description}
        />
      )}

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemprop="name" content={title} />
      {description && (
        <meta
          itemprop="description"
          content={description}
        />
      )}
      {reborn && (
        <meta itemprop="image" content={`${HOSTNAME}${reborn.coverPicture.normal}`} />
      )}

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content={`${HOSTNAME}${pathname}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      {description && (
        <meta
          property="og:description"
          content={description}
        />
      )}
      {reborn && (
        <meta property="og:image" content={`${HOSTNAME}${reborn.coverPicture.normal}`} />
      )}

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      {description && (
        <meta
          name="twitter:description"
          content={description}
        />
      )}
      {reborn && (
        <meta name="twitter:image" content={`${HOSTNAME}${reborn.coverPicture.normal}`} />
      )}
    </Helmet>
  )
}

export default SeoMetaTags
