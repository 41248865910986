import React, { createContext, useContext, useState } from 'react'

const DEFAULT_CONTEXT = {}
const AppContext = createContext(DEFAULT_CONTEXT)

export const AppContextProvider = ({ initialLocale, children }) => {
  const [currentLocale, _setCurrentLocale] = useState(initialLocale)

  const setCurrentLocale = (locale) => {
    console.log('Changed locale to', locale)
    I18n.locale = locale
    _setCurrentLocale(locale)
  }

  return (
    <AppContext.Provider
      value={{
        setCurrentLocale,
        currentLocale
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)
