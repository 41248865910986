import classNames from 'classnames'
import React from 'react'
import RebornCard from './RebornCard'
import Breadcrumb from './Breadcrumb'

const RebornCardsList = ({ reborns, index }) => {
  if (reborns.length === 0) return null

  return (
    <div className={classNames('sm:mx-32', { 'mt-8': index > 0 })}>
      <div className='flex flex-row flex-wrap justify-around justify-items-center'>
        {reborns.map((reborn) => <RebornCard key={reborn.slug} reborn={reborn} className='mx-2' />)}
      </div>
    </div>
  )
}

const RebornsList = ({ reborns, status }) => {
  const withStatus = (reborns, onlyStatus) => reborns.filter((reborn) => reborn.status === onlyStatus)

  return (
    <>
      <Breadcrumb status={status} />
      <RebornCardsList index={0} reborns={withStatus(reborns, 'for_sale')} />
      <RebornCardsList index={1} reborns={withStatus(reborns, 'received').sort(({ kitName: aKitName}, { kitName: bKitName }) => aKitName.localeCompare(bKitName))} />
      <RebornCardsList index={2} reborns={withStatus(reborns, 'sold')} />
    </>
  )
}

export default RebornsList
