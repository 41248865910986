import I18n from 'i18n-js'
import FR_TRANSLATIONS from './fr'
import EN_TRANSLATIONS from './en'

I18n.defaultLocale = 'fr'
I18n.locale = 'fr'

I18n.translations = {
  fr: FR_TRANSLATIONS,
  en: EN_TRANSLATIONS
}

export default I18n
