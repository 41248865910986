import React, { createContext, useContext, useState } from 'react'

const DEFAULT_CONTEXT = {
  reborns: [],
  addToCart: null,
  cartContains: null,
  removeFromCart: null
}
const CartContext = createContext(DEFAULT_CONTEXT)

export const CartContextProvider = ({ children }) => {
  const [rebornsInCart, setRebornsInCart] = useState([])

  const addToCart = (reborn) => setRebornsInCart([...rebornsInCart, reborn])

  const cartContains = (rebornToFind) => rebornsInCart.find((reborn) => reborn.id === rebornToFind.id)

  const removeFromCart = (rebornToRemove) => {
    setRebornsInCart(rebornsInCart.filter((reborn) => reborn.id !== rebornToRemove.id))
  }

  return (
    <CartContext.Provider
      value={{
        addToCart,
        cartContains,
        removeFromCart,
        content: {
          reborns: rebornsInCart
        }
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCartContext = () => useContext(CartContext)
